import * as React from 'react';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';

import grid from '../grid';
import { AppState } from '../types';


interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    // eslint-disable-next-line
    const [] = useState({
        menuUsers: false,
        menuIssues: false,
        menuGrid: false,
    });
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change


    return (
        <div style={{marginTop:30}}>
            {' '}
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />

                <MenuItemLink
                    to={'/userDetail'}
                    primaryText={translate(`resources.userDetail.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/homeValuation'}
                    primaryText={translate(`resources.homeValuation.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                 <MenuItemLink
                    to={'/promoCodeRecords'}
                    primaryText={translate(`resources.promocodeRecords.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/accountClosure'}
                    primaryText={translate(`resources.accountClosure.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/giftCardDashboard'}
                    primaryText={translate(`resources.giftCardDashboard.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/applyPromoReferralCode'}
                    primaryText={translate(`resources.applyPromoReferralCode.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/transferRewardAmount'}
                    primaryText={translate(`resources.transferRewardAmount.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/updatePayToLender'}
                    primaryText={translate(`resources.updatePayToLender.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                 <MenuItemLink
                    to={'/updateDirectDebit'}
                    primaryText={translate(`resources.updateDirectDebit.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                  <MenuItemLink
                    to={'/cancelGiftCard'}
                    primaryText={translate(`resources.cancelGiftCard.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/addressAndCreditReport'}
                    primaryText={translate(`resources.addressAndCreditReport.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/pullCreditFile'}
                    primaryText={translate(`resources.pullCreditFile.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/UserMortgageData'}
                    primaryText={translate(`resources.UserMortgageData.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/generateBrokerReport'}
                    primaryText={translate(`resources.generateBrokerReport.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                 <MenuItemLink
                    to={'/getVerificationLink'}
                    primaryText={translate(`resources.getVerificationLink.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                 <MenuItemLink
                    to={'/updateGiftCardBlackList'}
                    primaryText={translate(`resources.updateGiftCardBlackList.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/pnNotifier'}
                    primaryText={translate(`resources.pnNotifier.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/remortgageLetter'}
                    primaryText={translate(`resources.remortgageLetter.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/admin/grid`}
                    primaryText={translate(`resources.grid.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                 <MenuItemLink
                    to={`/admin/monitor-debit-algo-performance`}
                    primaryText={translate(`resources.debitCalculator.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/admin/inactive-users`}
                    primaryText={translate(`resources.userSessionInfo.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                 <MenuItemLink
                    to={`/display-payments`}
                    primaryText={translate(`resources.userPayments.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
               
                {/* <MenuItemLink
                    to={`/admin/filter-users`}
                    primaryText={translate(`resources.filterUsers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                /> */}

                <MenuItemLink
                    to={'/masterToEwalletFailure'}
                    primaryText={translate(`resources.masterToEwalletFailure.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/redisCacheCleaner'}
                    primaryText={translate(`resources.redisCacheCleaner.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/updatePendingTask'}
                    primaryText={translate(`resources.updatePendingTask.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/updateUserPendingAction'}
                    primaryText={translate(`resources.updateUserPendingAction.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/ppsPayee'}
                    primaryText={translate(`resources.ppsPayee.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/removeUserFromSuppressionList'}
                    primaryText={translate(`resources.removeUserFromSuppressionList.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/removePinForUser'}
                    primaryText={translate(`resources.removePinForUser.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/forcedLogoutUser'}
                    primaryText={translate(`resources.forcedLogoutUser.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={'/deleteUser'}
                    primaryText={translate(`resources.deleteUser.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                  <MenuItemLink
                    to={'/manageUser'}
                    primaryText={translate(`resources.manageUser.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<grid.icon />}
                    sidebarIsOpen={open}
                    dense={dense}
                />
{/* 
              <MenuItemLink
                    to={`/admin/grid/users`}
                    primaryText={translate(`resources.users.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<users.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            <MenuItemLink
                    to={`/issues`}
                    primaryText={translate(`resources.issues.name`, {
                        smart_count: 0,
                    })}
                    leftIcon={<issues.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                /> */}
            {isXSmall && (
                <MenuItemLink
                    to="/configuration"
                    primaryText={translate('pos.configuration')}
                    leftIcon={<SettingsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
