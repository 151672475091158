import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";

import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  Notification, useTranslate, useNotify
} from "react-admin";


import { ENDPOINTS } from "../apiConstants";
import { getConfigGet } from "../getConfig";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "50vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  para: {
    marginBottom: '0px'
  },
  input_container: {
    display: "flex"
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
    marginLeft: "1em",
    minWidth: 100
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  table: {
    border: "1px solid lightgray",
    borderCollapse: "collapse"
  },
  tdStyle: {
    '& td, th': {
      borderTop: "1px solid lightgray",
      borderBottom: "1px solid lightgray",
      width: "150px",
      height: "30px",
      textAlign: "center"
    }
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  email?: string;
}

const { Form } = withTypes<FormValues>();

const UserMortgageData = () => {
  const [loading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [mortgageDetail, setmortgageDetail] = useState([]);

  const userMortgage = (inputData: any) => {
    setmortgageDetail([])
    console.log("inside ", inputData)
    let config = getConfigGet({ email: inputData.email })


    axios.get(ENDPOINTS.USER_MORTGAGE_DATA, config)
      .then((res: any) => {
        setmortgageDetail(res.data || res.data.mortgages)
        notify('Success', "info", "", undefined, 50000);
      })
      .catch((error) => {
        notify(error?.response?.data?.message || 'Error while getting mortgage data', "error", "", undefined, 50000)
      });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    return errors;
  };

  let resultHeaders = ['account no', 'start date', 'lender', 'current balance amount', 'fixed payment amount', 'term remaining in months', 'end date', 'is selected mortgage'];

  return (
    <>
      <Form
        onSubmit={userMortgage}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <div className={classes.form}>
                <div className={classes.input_container}>
                  <Field
                    autoFocus
                    name="email"
                    // @ts-ignore
                    component={renderInput}
                    label={translate("pos.email")}
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  User mortgage data
                </Button>
              </CardActions>
              <p>Note: Provides all active mortgages from DB for user</p>
              {mortgageDetail.length ?
                <div>
                  <table className={classes.table}>
                    <thead>
                      <tr className={classes.tdStyle} >
                        {resultHeaders.map((header) => {
                          return <th>{header}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {mortgageDetail.map((t: any) => (
                        <tr className={classes.tdStyle} key={t.addressID}>
                          <td>{t.maskedAccountNumber}</td>
                          <td>{t.start_date}</td>
                          <td>{t.company_name}</td>
                          <td>{t.current_balance_amount}</td>
                          <td>{t.fixed_payment_amount}</td>
                          <td>{t.fixed_payment_count}</td>
                          <td>{t.end_date}</td>
                          <td>{t.is_selected}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div> : null}
              <Notification />
            </div>

          </form>
        )}
      />
    </>
  );
};

export default UserMortgageData;
