import * as React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import { Dashboard } from './dashboard';
import Register from './layout/Register';
import AccountClosure from "./layout/AccountClosure"
import HomeValuation from "./layout/HomeValuation"
import AddressAndCreditReport from './layout/AddressAndCreditReport';
import PullCreditFile from './layout/pullCreditFile';
import UserDetail from './layout/userDetail';
import TransferRewardAmount from './layout/transferRewardAmount';
import RedisCacheCleaner from './layout/RedisCacheCleaner';
import PpsPayee from './layout/PpsPayee';
import UpadtePayToLender from './layout/updatePayToLender';
import UpdatePendingTask from './layout/updatePendnigTask';
import UpdateUserPendingAction from './layout/updateUserPendingAction';
import ApplyPromoReferralCode from './layout/ApplyPromoReferralCode';

import DeleteUser from './layout/DeleteUser';
import ManageUser from './layout/ManageUser';
import PNNotifier from './layout/PNNotifier';
import MasterToEwalletFailure from './layout/MasterToEwalletFailure';
import PromoCodeRecords from './layout/PromoCodeRecords';
import UpadteDirectDebit from './layout/updateDirectDebit';
import GetVerificationLink from './layout/GetVerificationLink';
import GiftCardDashboard from './layout/GiftCardDashboard';
import UpdateGiftCardBlacklist from './layout/updateGiftCardBlacklist';
import RemortgageLetter from './layout/RemortgageLetter';
import GenerateBrokerReport from './layout/GenerateBrokerReport';
import CancelGiftCard from './layout/CancelGiftCard';
import RemoveUserFromSuppressionList from './layout/RemoveUserFromSuppressionList';
import RemovePinForUser from './layout/RemovePinForUser';
import ForcedLogutUser from './layout/ForceLogoutUser';
import UserMortgageData from './layout/UserMortgageData';

export default [
<Route exact path="/configuration" render={() => <Configuration />} />,
<Route exact path="/register" noLayout render={()=><Register/>}/>,
<Route exact path="admin/dashboard" render={()=><Dashboard/>}/>,
<Route exact path="/userDetail" render={()=><UserDetail/>}/>,
<Route exact path="/accountClosure" render={()=><AccountClosure/>}/>,
<Route exact path="/promoCodeRecords" render={()=><PromoCodeRecords/>}/>,
<Route exact path="/giftCardDashboard" render={()=><GiftCardDashboard/>}/>,
<Route exact path="/homeValuation" render={()=><HomeValuation/>}/>,
<Route exact path="/applyPromoReferralCode" render={()=><ApplyPromoReferralCode/>}/>,
<Route exact path="/transferRewardAmount" render={()=><TransferRewardAmount/>}/>,
<Route exact path="/updatePayToLender" render={()=><UpadtePayToLender/>}/>,
<Route exact path="/updateDirectDebit" render={()=><UpadteDirectDebit/>}/>,
<Route exact path="/addressAndCreditReport" render={()=><AddressAndCreditReport/>}/>,
<Route exact path="/masterToEwalletFailure" render={()=><MasterToEwalletFailure/>}/>,
<Route exact path="/pullCreditFile" render={()=><PullCreditFile/>}/>,
<Route exact path="/generateBrokerReport" render={()=><GenerateBrokerReport/>}/>,
<Route exact path="/pnNotifier" render={()=><PNNotifier/>}/>,
<Route exact path="/remortgageLetter" render={()=><RemortgageLetter/>}/>,
<Route exact path="/redisCacheCleaner" render={()=><RedisCacheCleaner/>}/>,
<Route exact path="/updatePendingTask" render={()=><UpdatePendingTask/>}/>,
<Route exact path="/updateUserPendingAction" render={()=><UpdateUserPendingAction/>}/>,
<Route exact path="/getVerificationLink" render={()=><GetVerificationLink/>}/>,
<Route exact path="/updateGiftCardBlackList" render={()=><UpdateGiftCardBlacklist/>}/>,
<Route exact path="/ppsPayee" render={()=><PpsPayee/>}/>,
<Route exact path="/deleteUser" render={()=><DeleteUser/>}/>,
<Route exact path="/manageUser" render={()=><ManageUser/>}/>,
<Route exact path="/cancelGiftCard" render={()=><CancelGiftCard/>}/>,
<Route exact path="/removeUserFromSuppressionList" render={()=><RemoveUserFromSuppressionList/>}/>,
<Route exact path="/removePinForUser" render={()=><RemovePinForUser/>}/>,
<Route exact path="/forcedLogoutUser" render={()=><ForcedLogutUser/>}/>,
<Route exact path="/userMortgageData" render={()=><UserMortgageData/>}/>
    
];
