import * as React from "react";
import { useState } from "react";

import { Field, withTypes } from "react-final-form";

import axios from "axios";

import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Notification, useTranslate, useNotify } from "react-admin";


import { ENDPOINTS } from "../apiConstants";
import { getConfigPost } from "../getConfig";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  email?: string;
}

const { Form } = withTypes<FormValues>();

const RemovePinForUser = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const handleSubmit = (inputData: any) => {
    const { email } = inputData;

    let headers = getConfigPost()
    let params = {
      email: email
    }

    axios.patch(ENDPOINTS.REMOVE_PIN_FOR_USER, {}, { headers, params })
      .then((res: any) => {
        notify(`Successfully removed pin for user`, "info", "", undefined, 50000);
        setLoading(false);
      })
      .catch((error) => {
        notify(error?.response?.data?.message || `Failed to remove pin for user`, "error", "", undefined, 50000)
      });

  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  autoFocus
                  name="email"
                  // @ts-ignore
                  component={renderInput}
                  label={translate("pos.email")}
                  disabled={loading}
                />
              </div>
            </div>
            <CardActions className={classes.actions}>
              <Button
                variant="contained"
                type="submit"
                color='primary'
                disabled={loading}
                fullWidth
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                Remove pin for user
              </Button>
            </CardActions>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

export default RemovePinForUser;